import InfiniteMarquee from 'infinite-marquee';
import gsap from 'gsap';

export default () => {
  // Create a new matchMedia instance
  const mm = gsap.matchMedia();

  // Media query to run the marquee on tablet and mobile
  mm.add('(max-width: 991px)', () => {
    const marquee = new InfiniteMarquee({
      el: document.querySelector('.marquee'), // Wrapper in Webflow
      direction: 'right',
      duration: 20,
      css: false,
    });

    // eslint throws an error if the variable is not used
    marquee; //eslint-disable-line
  });
};
