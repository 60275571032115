import { createApp } from 'petite-vue';
import currency from 'currency.js';
import { personState } from './PersonPicker';
import { popupState } from '../Popup/Popup';
import { waitList } from './WaitList';
import { interestTopics, checkoutButton } from '../Question/QuestionPopup';
import getPricingObject from './getPricingObject';

export default () => {
  const getUrlAddons = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const addons = urlParams.get('addons');

    // Return null if addons are not set
    if (!addons) {
      return null;
    }

    return addons.split(',');
  };

  const getIndustry = () => {
    const urlParams = new URLSearchParams(window.location.search);
    let industry = urlParams.get('industry');
    industry = decodeURI(industry);

    return industry;
  };

  const getEmployeeNumber = () => {
    let employeeNumber = new URLSearchParams(window.location.search).get('employeeNumber');

    // Actucally not necessary, correctNumber() in Personpicker can also do and will set it to 1
    if (employeeNumber < 1) {
      employeeNumber = 1;
    }

    return employeeNumber;
  };

  const checkRedirect = () => {
    const redirectFlag = new URLSearchParams(window.location.search).get('redirect');

    return redirectFlag;
  };

  // Redirect to price calculator if industry is not set
  if (getIndustry() === 'null' || !getUrlAddons() || !getEmployeeNumber()) {
    if (checkRedirect() !== 'false') {
      window.location.replace('/price-caculator');
    }
  }

  const euro = (value) =>
    currency(value, {
      symbol: '€',
      separator: '.',
      decimal: ',',
      fromCents: false,
    });

  function TopicSelector(props) {
    return {
      $template: '#topic-selector-template',
      typeMode: props.type,
      loadInitialTopic() {
        const urlParams = new URLSearchParams(window.location.search);
        const urlTopic = urlParams.get('topic');

        const topicSlug = urlTopic || 'arbeitssicherheit';

        const element = document.querySelectorAll(`[item-slug-value="${topicSlug}"]`);

        this.handleSelection(element[1]);
      },
      handleSelection(element) {
        // interestTopics.array.push(topicSlug);
        const topicSlug = element.getAttribute('item-slug-value');
        const activeState = element.getAttribute('active');
        const activeIcon = element.querySelector('.pricing_topics-active-icon');
        const inactiveIcon = element.querySelector('.pricing_topics-inactive-icon');

        const addValue = (value) => {
          interestTopics.array.push(value);
          try {
            window.optibaseSendConversionEvent('thema_gewhlt');
          } catch (error) {
            console.warn('Optibase Problem', error);
          }
        };

        const removeValue = (value) => {
          interestTopics.array = interestTopics.array.filter((topic) => topic !== value);
        };

        if (activeState === 'false' || activeState === null) {
          addValue(topicSlug);
          element.setAttribute('active', 'true');
          element.classList.add('has-blue-border');
          activeIcon.style.display = 'block';
          inactiveIcon.style.display = 'none';
        }

        if (activeState === 'true') {
          if (interestTopics.array.length !== 1) {
            removeValue(topicSlug);
            element.setAttribute('active', 'false');
            element.classList.remove('has-blue-border');
            activeIcon.style.display = 'none';
            inactiveIcon.style.display = 'block';
          }
        }
      },
    };
  }

  createApp({
    TopicSelector,
    addons: getUrlAddons(),
    priceIndex: 0,
    priceTotal: 0,
    industry: getIndustry(),
    personState,
    popupState,
    waitList,
    interestTopics,
    checkoutButton,
    setPriceIndex() {
      switch (true) {
        case personState.personNumber <= 9:
          this.priceIndex = 0;
          break;
        case personState.personNumber <= 49:
          this.priceIndex = 1;
          break;
        case personState.personNumber <= 249:
          this.priceIndex = 2;
          break;
        case personState.personNumber >= 250:
          this.priceIndex = 3;
          break;
        default:
          this.priceIndex = 0;
      }
    },
    displaySinglePrice(price) {
      this.setPriceIndex();
      return euro(price[this.priceIndex]).format();
    },
    displayMultipliedPrice(price) {
      this.setPriceIndex();
      return euro(price[this.priceIndex]).multiply(personState.personNumber).format();
    },
    displayStepPrice() {
      switch (true) {
        case personState.personNumber <= 9 && interestTopics.array.length === 1:
          return euro(20.5).format();
        case personState.personNumber <= 9 && interestTopics.array.length === 2:
          return euro(39.13).format();
        case personState.personNumber <= 9 && interestTopics.array.length === 3:
          return euro(46.22).format();
        case personState.personNumber <= 9 && interestTopics.array.length === 4:
          return euro(61.12).format();

        case personState.personNumber <= 49 && interestTopics.array.length === 1:
          return euro(18.45).format();
        case personState.personNumber <= 49 && interestTopics.array.length === 2:
          return euro(35.22).format();
        case personState.personNumber <= 49 && interestTopics.array.length === 3:
          return euro(41.59).format();
        case personState.personNumber <= 49 && interestTopics.array.length === 4:
          return euro(55.01).format();

        case personState.personNumber <= 249 && interestTopics.array.length === 1:
          return euro(15.87).format();
        case personState.personNumber <= 249 && interestTopics.array.length === 2:
          return euro(31.7).format();
        case personState.personNumber <= 249 && interestTopics.array.length === 3:
          return euro(37.43).format();
        case personState.personNumber <= 249 && interestTopics.array.length === 4:
          return euro(49.51).format();

        case personState.personNumber >= 250 && interestTopics.array.length === 1:
          return euro(13.56).format();
        case personState.personNumber >= 250 && interestTopics.array.length === 2:
          return euro(28.53).format();
        case personState.personNumber >= 250 && interestTopics.array.length === 3:
          return euro(33.69).format();
        case personState.personNumber >= 250 && interestTopics.array.length === 4:
          return euro(44.56).format();

        default:
          return 'auf Anfrage';
      }
    },
    get multipliedStepPrice() {
      return euro(this.displayStepPrice()).multiply(personState.personNumber).format();
    },
    filterPriceItem(item) {
      const itemAddons = item.querySelectorAll('[pricing-item="addon"]');
      const itemAddonSlugs = [];
      const showAlways = item.getAttribute('show-always');

      itemAddons.forEach((addon) => {
        const slug = addon.getAttribute('addon-slug');

        itemAddonSlugs.push(slug);
      });

      function arrayCompare(_arr1, _arr2) {
        if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) {
          return false;
        }

        // .concat() to not mutate arguments
        const arr1 = _arr1.concat().sort();
        const arr2 = _arr2.concat().sort();

        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) {
            return false;
          }
        }

        return true;
      }

      if (!arrayCompare(itemAddonSlugs, this.addons) && showAlways !== 'true') {
        item.remove();
      }
    },
    showWaitlist(packageName, price, packageTopicCount, packagePublicName) {
      this.setPriceIndex();
      waitList.formData.price = euro(price[this.priceIndex])
        .multiply(personState.personNumber)
        .format();

      // 20% discount
      waitList.formData.discountPrice = euro(waitList.formData.price).multiply(0.8).format();

      waitList.formData.package = packageName;
      waitList.formData.personNumber = personState.personNumber;
      waitList.formData.industry = this.industry;
      waitList.formData.initUserlURL = sessionStorage.getItem('init-userURL');
      waitList.formData.packagePublicName = packagePublicName;

      interestTopics.maxTopics = packageTopicCount;

      if (packageTopicCount === 1) {
        waitList.hideTopicSelector = false;
        waitList.formData.topicWordNumber = 'einen';
        waitList.formData.topicWordNumberNoun = 'Schulungsbereich';
      }

      if (packageTopicCount === 2) {
        waitList.hideTopicSelector = false;
        waitList.formData.topicWordNumber = 'zwei';
        waitList.formData.topicWordNumberNoun = 'Schulungsbereiche';
      }

      if (packageTopicCount === 0) {
        waitList.hideTopicSelector = true;
      }

      console.log(interestTopics.array);

      popupState.togglePopup();
    },
    getDomainLanguage() {
      const firstPath = location.pathname.split('/')[1];

      if (firstPath === 'en') {
        return 'en';
      }
      return 'de';
    },
    openInfoPopup(triggerElement) {
      const slug = triggerElement.getAttribute('popupTriggerSlug');

      const popupElement = document.querySelector(`[info-popup="${slug}"]`);

      popupElement.style.display = 'block';
    },
    closeInfoPopup(closeElement) {
      const slug = closeElement.getAttribute('popupCloseSlug');

      const popupElement = document.querySelector(`[info-popup="${slug}"]`);

      popupElement.style.display = 'none';
    },
    redirectExternalCheckout(buyPackage, mode) {
      let domain;

      const getMatchingUrl = () => {
        const pricingObject = getPricingObject();

        function filterPricingObject(condition) {
          if (condition.package === 'all-in-one-new-pricing') {
            return [
              {
                checkoutDevelopment:
                  'https://my-uat.is-fox.com/register?priceId=price_1PpoXORqCu6CCU9Ln64ViZtc',
                checkoutProduction:
                  'https://my.is-fox.com/register?priceId=price_1PnDrqRqCu6CCU9LxcBmK9qi',
              },
            ];
          }

          return pricingObject.filter((item) => {
            // Special for all in one , give pricing slug here
            // quick fix for now
            // refactor later

            if (condition.package && item.package !== condition.package) {
              // Filter by package name
              return false;
            }

            // Filter by exact match of addons
            if (condition.addons) {
              const addonsMatch =
                condition.addons.length === item.addons.length &&
                condition.addons.every((addon) => item.addons.includes(addon));
              if (!addonsMatch) {
                return false;
              }
            }

            return true;
          });
        }

        const filteredPricing = filterPricingObject({
          package: buyPackage,
          addons: this.interestTopics.array,
        });

        if (window.location.hostname === 'is-fox.webflow.io') {
          domain = filteredPricing[0].checkoutDevelopment;
        }

        if (window.location.hostname === 'get.is-fox.com') {
          domain = filteredPricing[0].checkoutProduction;
        }
      };

      getMatchingUrl();

      if (domain === undefined) {
        console.warn('No matching Plan URL found');
        return;
      }

      domain += `&quantity=${personState.personNumber}`;

      const domainLanguage = this.getDomainLanguage();

      domain += `&lang=${domainLanguage}`;

      if (mode === 'trial') {
        domain += '&freeTrial=true';
      }

      window.location.href = domain;
    },
  }).mount('#pricing-component');
};
