import Splide from '@splidejs/splide';

export default () => {
  // This is the Sliders for the course content
  // which should be visible only on mobile and set to it accordingly in Webflow

  // make sure all ids for the sliders can be found in Webflow
  // where you want to use the slider
  // should be placed on their respective wrappers

  // for configuration of the sliders check the Splide documentation

  // Only initialize the slider if the element is present on the page
  // otherwise will throw an error because Splide can't mount
  if (document.querySelector('#course-content-slider')) {
    new Splide('#course-content-slider', {
      fixedWidth: '90%',
      gap: '1rem',
      focus: 'center',
    }).mount();
  }

  // Only initialize the slider if the element is present on the page
  // otherwise will throw an error because Splide can't mount
  if (document.querySelector('#testimonial-slider-1')) {
    // Set config as variable to reuse it for both sliders
    const testomonialSplideConfig = {
      type: 'loop',
      fixedWidth: '90%',
      // Run the slider only on tablet and mobile
      mediaQuery: 'min',
      breakpoints: {
        992: {
          destroy: true,
        },
      },
    };

    // Mount immidiatly and store the instance in a variable
    // The sliders sit in Tabs in Webflow so we need to refresh the slider when the tab is changed
    // but mount directly here no matter which tab is show first to the user
    // don't forget to match the class of the tab links in Webflow
    const testimonialTabsControl = document.querySelectorAll('.testimonial_tab-link');
    const testimonialSlider1 = new Splide('#testimonial-slider-1', testomonialSplideConfig).mount();
    const testimonialSlider2 = new Splide('#testimonial-slider-2', testomonialSplideConfig).mount();

    testimonialTabsControl.forEach((tab) => {
      // Initialize everytime a tab is clicked and changed in Webflow
      // The settimout is needed because the content is not visible immediately
      // Webflow has a fade effect on the tabs and the default animation is 300ms,
      // so we add 10ms more to be sure the content is visible
      // might be better to use the Webflow API to detect when the tab is visible
      // for example an event listener on the tab content
      tab.addEventListener('click', () => {
        setTimeout(() => {
          testimonialSlider1.refresh();
          testimonialSlider2.refresh();
        }, 310);
      });
    });
  }

  // Only initialize the slider if the element is present on the page
  // otherwise will throw an error because Splide can't mount
  if (document.querySelector('#lp-intro-slider')) {
    new Splide('#lp-intro-slider', {
      fixedWidth: '92.5%',
      gap: '0rem',
      focus: 'center',
      // Run the slider only on tablet and mobile
      mediaQuery: 'min',
      breakpoints: {
        992: {
          destroy: true,
        },
      },
    }).mount();
  }
};
