import gsap from 'gsap';

export default () => {
  // This is the FAQ toggle handler for the FAQ section
  // Toggling the FAQ body on click of the FAQ head

  // The trigger for all FAQ Items, each of them needs to have this class on the question head
  const faqTriggers = document.querySelectorAll('.faq_head');

  // Loop through all FAQ triggers
  faqTriggers.forEach((trigger) => {
    // Value on the attribute is a unique reference to the body of the FAQ item
    // The value can be be for example the Slug of the cms item
    // this has the same value as the body reference
    const triggerReference = trigger.getAttribute('faq-head-ref');

    // The icon that will be rotated on open and close
    const triggerIcon = trigger.querySelector('.faq_head-icon');

    // Timeline instance for each unique FAQ item
    // Paused so the animation doesn't play on page load
    const faqOpenTimeline = gsap.timeline({ paused: true, yoyo: true });

    // track in variable if the FAQ item is open or closed
    // for tracking first and second time clicks
    let isOpen = false;

    // Animation sets height to auto to open Faq body which contains the answer
    // also rotates the icon
    // in webflow set styles of the elements to the open state to make sure the animation works
    // [faq-body-ref="${triggerReference}"]  is the body of the FAQ item
    // which has the same value as the head reference for example the slug
    faqOpenTimeline
      .from(`[faq-body-ref="${triggerReference}"]`, {
        display: 'none',
        // Problem when being set to 0 duration
        // immidiately hide body when closed
        duration: 0.000001,
      })
      .fromTo(
        `[faq-body-ref="${triggerReference}"]`,
        {
          height: '0',
        },
        {
          height: 'auto',
          duration: 0.5,
          ease: 'circ.inOut',
        }
      )
      .from(
        triggerIcon,
        {
          rotateZ: -180,
        },
        '<0' //eslint-disable-line
      );

    // Add click event listener to the trigger
    // play and reverse the timeline depending on the state of the isOpen variable
    trigger.addEventListener('click', () => {
      if (!isOpen) {
        // if is closed play the timeline and set isOpen to true for next click
        isOpen = true;
        faqOpenTimeline.play();
      } else {
        // if is open reverse the timeline and set isOpen to false for next click
        isOpen = false;
        faqOpenTimeline.reverse();
      }
    });
  });
};
