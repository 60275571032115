import { createApp } from 'petite-vue';
import currency from 'currency.js';
import { personState } from './PersonPicker';
import getPricingObject from './getPricingObject';

export default () => {
  // Function for formatting currency strings with currency.js
  const euro = (value) =>
    // eslint-disable-next-line
    currency(value, {
      symbol: '€',
      separator: '.',
      decimal: ',',
      fromCents: false,
    });

  // Petite Vue component for the topic selector
  function TopicSelector(props) {
    return {
      // This component uses the template with the ID 'topic-selector-template'
      // See Petite Vue documentation for more information
      // Make sure element exists in Webflow
      $template: '#topic-selector-template',
      // Typemode is used to style the component differently based on the type
      // All In One and Flex have different styles and All In One can't have topics selected
      typeMode: props.type,
      // Display a topic passed from URL when coming from Course page
      loadInitialTopic() {
        // Get parameter from URL for choosing default topic
        const urlParams = new URLSearchParams(window.location.search);
        const urlTopic = urlParams.get('topic');

        // If no topic is passed, 'arbeissicherheit' is selected by default
        const topicSlug = urlTopic || 'cyber-security';

        // Get the course topic selection by slug
        // Attribute must be present on Selector wrapper element
        const element = document.querySelectorAll(`[item-slug-value="${topicSlug}"]`);

        // Pass the element in here so component can handle the selection
        this.handleSelection(element[1]);
      },
      // Handle the selection of a topic when clicking on it in Flex package
      handleSelection(element) {
        // Fill the array that will be used for filtering the pricing object
        // and finding the correct checkout URL
        // this.interestTopics.array is part the mounted app down below

        // All elements inside of the topic picker
        const topicSlug = element.getAttribute('item-slug-value');
        const activeState = element.getAttribute('active');
        const activeIcon = element.querySelector('.pricing_topics-active-icon');
        const inactiveIcon = element.querySelector('.pricing_topics-inactive-icon');

        const addValue = (value) => {
          this.interestTopics.array.push(value);
          // Send conversion event to Optibase
          window.optibaseSendConversionEvent('thema_gewhlt');
        };

        const removeValue = (value) => {
          // Remove the value from the array
          this.interestTopics.array = this.interestTopics.array.filter((topic) => topic !== value);
        };

        // When not selected and not active, add value
        if (activeState === 'false' || activeState === null) {
          addValue(topicSlug);
          // Style Topic picker and icons and add attribute to handle next time
          element.setAttribute('active', 'true');
          element.classList.add('has-blue-border');
          activeIcon.style.display = 'block';
          inactiveIcon.style.display = 'none';
        }

        // When selected and active, remove value
        if (activeState === 'true') {
          // Prevent removing if only one topic is selected
          if (this.interestTopics.array.length !== 1) {
            // Remove value if already active and is selected
            removeValue(topicSlug);
            // Remove styling
            element.setAttribute('active', 'false');
            element.classList.remove('has-blue-border');
            activeIcon.style.display = 'none';
            inactiveIcon.style.display = 'block';
          }
        }
      },
    };
  }

  createApp({
    TopicSelector, // load component into app
    priceIndex: 0,
    priceTotal: 0,
    // import personState from PersonPicker to use it in this component
    // the reactive personState object is shared between the two apps
    personState,
    // the interestTopics array is used to filter the pricing object
    interestTopics: {
      array: [],
      maxTopics: 3, // might be deprecated!
    },
    setPriceIndex() {
      switch (true) {
        case personState.personNumber <= 9:
          this.priceIndex = 0;
          break;
        case personState.personNumber <= 49:
          this.priceIndex = 1;
          break;
        case personState.personNumber <= 249:
          this.priceIndex = 2;
          break;
        case personState.personNumber >= 250:
          this.priceIndex = 3;
          break;
        default:
          this.priceIndex = 0;
      }
    },
    // This function is only used for the pricing of the All In One package
    displaySinglePrice(price) {
      this.setPriceIndex();
      return euro(price[this.priceIndex]).format();
    },
    // This function is only used for the pricing of the All In One package
    displayMultipliedPrice(price) {
      this.setPriceIndex();
      return euro(price[this.priceIndex]).multiply(personState.personNumber).format();
    },
    // Pricing for the Flex package is controlled here
    // Might move to a CMS based solution in the future
    displayStepPrice() {
      switch (true) {
        case personState.personNumber <= 9 && this.interestTopics.array.length === 1:
          return euro(20.5).format();
        case personState.personNumber <= 9 && this.interestTopics.array.length === 2:
          return euro(39.13).format();
        case personState.personNumber <= 9 && this.interestTopics.array.length === 3:
          return euro(46.22).format();
        case personState.personNumber <= 9 && this.interestTopics.array.length === 4:
          return euro(61.12).format();

        case personState.personNumber <= 49 && this.interestTopics.array.length === 1:
          return euro(18.45).format();
        case personState.personNumber <= 49 && this.interestTopics.array.length === 2:
          return euro(35.22).format();
        case personState.personNumber <= 49 && this.interestTopics.array.length === 3:
          return euro(41.59).format();
        case personState.personNumber <= 49 && this.interestTopics.array.length === 4:
          return euro(55.01).format();

        case personState.personNumber <= 249 && this.interestTopics.array.length === 1:
          return euro(15.87).format();
        case personState.personNumber <= 249 && this.interestTopics.array.length === 2:
          return euro(31.7).format();
        case personState.personNumber <= 249 && this.interestTopics.array.length === 3:
          return euro(37.43).format();
        case personState.personNumber <= 249 && this.interestTopics.array.length === 4:
          return euro(49.51).format();

        case personState.personNumber >= 250 && this.interestTopics.array.length === 1:
          return euro(13.56).format();
        case personState.personNumber >= 250 && this.interestTopics.array.length === 2:
          return euro(28.53).format();
        case personState.personNumber >= 250 && this.interestTopics.array.length === 3:
          return euro(33.69).format();
        case personState.personNumber >= 250 && this.interestTopics.array.length === 4:
          return euro(44.56).format();

        default:
          return 'auf Anfrage';
      }
    },
    // Pricing for the Flex package is controlled here
    get multipliedStepPrice() {
      return euro(this.displayStepPrice()).multiply(personState.personNumber).format();
    },
    filterPriceItem(item) {
      const itemAddons = item.querySelectorAll('[pricing-item="addon"]');
      const itemAddonSlugs = [];
      const showAlways = item.getAttribute('show-always');

      itemAddons.forEach((addon) => {
        const slug = addon.getAttribute('addon-slug');
        itemAddonSlugs.push(slug);
      });

      // Checks if elements in two arrays are the same
      // Used to compare the selected topics with the topics in the pricing object
      function arrayCompare(_arr1, _arr2) {
        if (!Array.isArray(_arr1) || !Array.isArray(_arr2) || _arr1.length !== _arr2.length) {
          return false;
        }

        // .concat() to not mutate arguments
        const arr1 = _arr1.concat().sort();
        const arr2 = _arr2.concat().sort();

        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) {
            return false;
          }
        }

        return true;
      }

      // If the selected topics don't match the topics
      // in the pricing object, remove the item from the DOM
      if (!arrayCompare(itemAddonSlugs, this.addons) && showAlways !== 'true') {
        item.remove();
      }
    },
    getDomainLanguage() {
      // Function to get the language from the URL
      // Webflow localization uses /en or /de as the first path
      // eslint-disable-next-line
      const firstPath = location.pathname.split('/')[1];

      if (firstPath === 'en') {
        return 'en';
      }
      return 'de';
    },
    openInfoPopup(triggerElement) {
      // Call this function with the trigger element to open the popup
      const slug = triggerElement.getAttribute('popupTriggerSlug');

      // Find the popup element with the matching slug
      const popupElement = document.querySelector(`[info-popup="${slug}"]`);

      popupElement.style.display = 'block';
    },
    closeInfoPopup(closeElement) {
      // Call this function with the trigger element to close the popup
      const slug = closeElement.getAttribute('popupCloseSlug');

      // Find the popup element with the matching slug
      const popupElement = document.querySelector(`[info-popup="${slug}"]`);

      popupElement.style.display = 'none';
    },
    redirectExternalCheckout(buyPackage, mode) {
      let domain;

      const getMatchingUrl = () => {
        const pricingObject = getPricingObject();

        function filterPricingObject(condition) {
          if (condition.package === 'all-in-one-new-pricing') {
            return [
              {
                checkoutDevelopment:
                  'https://my-uat.is-fox.com/register?priceId=price_1PpoXORqCu6CCU9Ln64ViZtc',
                checkoutProduction:
                  'https://my.is-fox.com/register?priceId=price_1PnDrqRqCu6CCU9LxcBmK9qi',
              },
            ];
          }

          return pricingObject.filter((item) => {
            // Special for all in one , give pricing slug here
            // quick fix for now
            // refactor later

            if (condition.package && item.package !== condition.package) {
              // Filter by package name
              return false;
            }

            // Filter by exact match of addons
            if (condition.addons) {
              const addonsMatch =
                condition.addons.length === item.addons.length &&
                condition.addons.every((addon) => item.addons.includes(addon));
              if (!addonsMatch) {
                return false;
              }
            }

            return true;
          });
        }

        const filteredPricing = filterPricingObject({
          package: buyPackage,
          addons: this.interestTopics.array,
        });

        if (window.location.hostname === 'is-fox.webflow.io') {
          domain = filteredPricing[0].checkoutDevelopment;
        }

        if (window.location.hostname === 'get.is-fox.com') {
          domain = filteredPricing[0].checkoutProduction;
        }
      };

      const getFt = () => {
        const ft = localStorage.getItem('ftp');

        const ftObject = JSON.parse(ft);

        let finalPath = '';

        ftObject.forEach((element) => {
          const key = Object.keys(element)[0];
          const value = Object.values(element)[0];

          finalPath += `&${key}=${value}`;
        });

        return finalPath;
      };

      const getLt = () => {
        const lt = localStorage.getItem('ltp');

        const ltObject = JSON.parse(lt);

        let finalPath = '';

        ltObject.forEach((element) => {
          const key = Object.keys(element)[0];
          const value = Object.values(element)[0];

          finalPath += `&${key}=${value}`;
        });

        return finalPath;
      };

      getMatchingUrl();

      if (domain === undefined) {
        console.warn('No matching Plan URL found');
        return;
      }

      domain += `&quantity=${personState.personNumber}`;

      const domainLanguage = this.getDomainLanguage();

      domain += `&lang=${domainLanguage}`;

      if (mode === 'trial') {
        domain += '&freeTrial=true';
      }

      try {
        domain += getFt();
      } catch (error) {
        console.warn('No ft found', error);
      }

      try {
        domain += getLt();
      } catch (error) {
        console.warn('No lt found', error);
      }

      window.location.href = domain;
    },
  }).mount('#pricing-component');
};
