import videojs from 'video.js';
import 'videojs-youtube';

export default () => {
  console.log('VideoJSPlayer');

  videojs('lp-video-youtube', {
    fluid: true,
    preload: 'none',
    controlBar: { pictureInPictureToggle: false },
    controls: true,
    techOrder: ['youtube'],
    sources: [
      {
        type: 'video/youtube',
        src: 'https://youtu.be/H_D733ax6e0',
      },
    ],
    youtube: {
      rel: 0,
      customVars: { wmode: 'transparent', rel: 0 },
    },
    poster:
      'https://cdn.prod.website-files.com/661ebeed2025e93986682bc7/6758e1ca973860528c6029ac_Bildschirmfoto%202024-12-11%20um%2001.49.32.webp',
  });

  // Problem with Youtube Short right now
  /*
  videojs('lp-video-youtube-mobile', {
    fluid: true,
    preload: 'none',
    controlBar: { pictureInPictureToggle: false },
    height: 700,
    controls: true,
    techOrder: ['youtube'],
    sources: [
      {
        type: 'video/youtube',
        src: 'https://youtube.com/shorts/gBhp69nhWxk',
      },
    ],
    youtube: {
      rel: 0,
      customVars: { wmode: 'transparent', rel: 0 },
    },
    poster:
      'https://cdn.prod.website-files.com/661ebeed2025e93986682bc7/6758e62e054d69b66a710604_Bildschirmfoto%202024-12-11%20um%2002.08.44.webp',
  });
  */
};
