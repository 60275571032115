import { createApp, reactive } from 'petite-vue';

// Reactive data for the person picker
// This is used to keep track of the person number in the URL
// Can be used to update the URL when the person number changes
// Can be used as module in other components
export const personState = reactive({
  // initial person number from URL
  // after that the number is adjusted from input or increment/decrement buttons
  personNumber: new URLSearchParams(window.location.search).get('employeeNumber'),
  updateURL() {
    // This function updates the URL with the new person number
    const url = new URL(window.location);
    url.searchParams.set('employeeNumber', this.personNumber);
    window.history.pushState(null, '', url.toString());
  },
});

export const PersonPicker = () => {
  createApp({
    personState, // import reactive data from above to use in the component
    incrementNumber: 1, // steps to increment or decrement the person number
    increment(mode) {
      // This function increments or decrements the person number
      // depending on the mode passed to it

      personState.personNumber = Math.floor(Number(personState.personNumber));

      if (mode === 'increase') {
        personState.personNumber += this.incrementNumber;
        personState.updateURL();
      }

      if (mode === 'decrease') {
        if (personState.personNumber >= 2) {
          personState.personNumber -= this.incrementNumber;
          personState.updateURL();
        }
      }
    },
    correctNumber() {
      // This function cleans up the person number if passed incorrectly in URL
      // or user tries inputting a string or something other than a number

      // first case if not a number, second case if number is less than 1,
      // third case if not an integer
      // helps correcting, function can be load on mount lifecycle in Webflow Component
      // or on click event in the component

      // elisnt giving error about incorrect use of isNaN
      // eslint-disable-next-line
      if (isNaN(personState.personNumber)) {
        personState.personNumber = 1;
        personState.updateURL();
      }

      if (personState.personNumber < 1) {
        personState.personNumber = 1;
      }

      if (!Number.isInteger(personState.personNumber)) {
        personState.personNumber = Math.floor(personState.personNumber);
      }
    },
  }).mount('#person-picker'); // make sure id matches the id in the Webflow component
};
