export default () => {
  // Video Modal handler for the popup on the course pages
  const components = document.querySelectorAll('[video-modal="component"]');

  components.forEach((component) => {
    // All attributes must be set in Webflow on the elements for this to work
    const modal = component.querySelector('[video-modal="lightbox"]');
    const openTrigger = component.querySelector('[video-modal="open-trigger"]');
    const closeTrigger = component.querySelectorAll('[video-modal="close-trigger"]');
    const video = component.querySelector('[video-modal="video"]');

    openTrigger.addEventListener('click', () => {
      modal.style.display = 'flex';
      video.play();
    });

    closeTrigger.forEach((trigger) => {
      trigger.addEventListener('click', () => {
        modal.style.display = 'none';
        video.pause();
      });
    });
  });
};
