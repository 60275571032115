export default () => {
  // This is the navigation login buttons handler
  // different urls depending on which domain the site is visited
  // href of the button is dynamically set based on the domain
  // Can be found in the navigation component in Webflow

  document.querySelectorAll('[nav-login-link]').forEach((button) => {
    const stagingURL = button.getAttribute('staging-url');
    const productionURL = button.getAttribute('production-url');

    // adjust hostname to match the devlopment url in Webflow
    if (window.location.hostname === 'is-fox.webflow.io') {
      button.href = stagingURL;
    } else {
      button.href = productionURL;
    }
  });
};
