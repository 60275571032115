export default () => {
  // This function is used to get the pricing object from the pricing section
  // in the Webflow CMS there is no direct API to use in the frontend and custom code like here
  // in future Webflow CMS Data can be mirrored to a custom database and fetched from there
  // but for now we need to use this workaround and hide the
  // data we need in the DOM and iterate from there and save it as an object
  // which we can use as a module in other components when needed

  // This is data for getting the correct link for the checkout on the pricing page

  // Fill array with data and retrun at the end of the function
  const pricingObject = [];

  // Iterate over all pricing objects in the DOM
  // make sure the structure is correct in Webflow and attributes match for the querySelector
  document.querySelectorAll('[pricing-object="package"]').forEach((item) => {
    const packageName = item.getAttribute('object-value');

    // Checkout links for production and development environments
    const checkoutProduction = item.getAttribute('checkout-production');
    const checkoutDevelopment = item.getAttribute('checkout-development');

    const addons = [];

    item.querySelectorAll('[pricing-object="addons"]').forEach((addon) => {
      const value = addon.getAttribute('object-value');
      addons.push(value);
    });

    const arrayObject = {
      package: packageName,
      addons,
      checkoutProduction,
      checkoutDevelopment,
    };

    pricingObject.push(arrayObject);
  });

  return pricingObject;
};
